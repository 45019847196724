/// <reference path="../../../typings/libraries.d.ts"/>

// ReSharper disable once InconsistentNaming
Alkami = Alkami || {};
Alkami.Helpers = Alkami.Helpers || {};


/**
 * Returns a query string containing the parameters for the given url
 * @param {string} url - The url for which to return the parameters string
 */
Alkami.Helpers.getQueryStringFromUrl = function(url: string) {
   url.match(/\?(.+)$/);
   const params = RegExp.$1;
   return params;
};

/**
 * Returns an object containing key value pairs of the parameters for the given url
 * @param {string} url - The url for which to return the parameters object
 */
Alkami.Helpers.getQueryObjectFromUrl = function(url: string) {
   let params = Alkami.Helpers.getQueryStringFromUrl(url);
   params = params.split('&');
   const queryStringList = {};
   for (let i = 0; i < params.length; i++) {
      const tmp = params[i].split('=');
      queryStringList[tmp[0]] = decodeURI(tmp[1]);
   }
   return queryStringList;
};

/**
 * Returns the value for a specific parameter in the given url
 * @param {string} url - The url for which to find the given parameter name
 * @param {name} name - The name of the parameter for which to get the value out of the url
 */
Alkami.Helpers.getUrlParamFromUrl = function(url: string, name: string) {
   name = name.replace(/[\[]/, '\\\[').replace(/[\]]/, '\\\]');
   const regexS = '[\\?&]' + name + '=([^&#]*)';
   const regex = new RegExp(regexS);
   const results = regex.exec(url);
   return results == null ? '' : results[1];
};

Alkami.Helpers.getQueryParams = function(returnObj: boolean) {
   console.warn('Alkami.Helpers.getQueryParams is deprecated. Please use URLSearchParams documentation: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams');
   const url = window.location.toString();
   if (returnObj) {
      return Alkami.Helpers.getQueryObjectFromUrl(url);
   }
   return Alkami.Helpers.getQueryStringFromUrl(url);
};


const getUrlParam = (name: string) => {
   console.warn('window.getUrlParam is deprecated. Please use URLSearchParams documentation: https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams');
   return Alkami.Helpers.getUrlParamFromUrl(window.location.hash, name);
}

const parseDate = (input: string, format: string): Date => {
   console.warn('window.parseDate is deprecated. Please use Alkami.Utils.DateHelper.parseDate')
   return Alkami.Utils.DateHelper.parseDate(input, format);
}

function maskCard(cardNo: string) {
   const localCardNo: string = cardNo || null;
   if (localCardNo === null) {
      return cardNo;
   }
   function variableLength(_str: string, p1: string, p2: string, p3: string) {
      const replace: string = Array(p2.length + 1).join('X');
      return p1.concat(replace, p3);
   }
   return localCardNo.replace(new RegExp('(\\d{6})(\\d{3,6})(\\d{4})', 'g'), variableLength);
}

/********************
* MONEY HELPERS
* TODO: consolidate these methods
* TODO: define consistent money Patterns
**********************/

/**
 * Returns a formatted dollar amount, including cents, based on a value provided, which can be a string, integer or float.
 * @param {string|integer|float} value - The value to format.
 * @param {boolean} allowZero - Whether or not a value of 0 should return a formatted amount ($0.00) or an empty string.
 */
const moneyRenderer = (amount: (string | number), allowZero?: boolean) => {
   console.warn('moneyRenderer is deprecated. Please use Alkami.CurrencyHelper.formatCurrency instead.');
   if (typeof allowZero === 'undefined') { allowZero = false; }
   // ReSharper disable once CoercedEqualsUsing
   if (!allowZero && (amount == '' || amount == null)) { return ''; }/* use coercion!!! to avoid returning $0.00 on blank $$ fields */

   if (amount === '' || amount === null) { return ''; }

   if (typeof amount === 'string') {
      amount = parseFloat((amount as string).replace(/[A-Za-z\,\$]/g, ''));
   }

   return (amount as number).toLocaleString('en-US');
}
const moneyRendererAllowZero = (v: (string | number)) => moneyRenderer(v, true);

function localizeAmount(num: number): string {
   console.warn('localizeAmount is deprecated. Please use Alkami.CurrencyHelper.formatCurrency instead.');
   if (typeof num === 'number') {
      const localNum: string = num.toFixed(2);
      return localNum;
   }
   return null;
}

function unlocalizeAmount(val: string): string {
   console.warn('unlocalizeAmount has been deprecated. Please use Alkami.Utils.CurrencyHelper.parseCurrency instead.')
   val = val.replace(/(\$|,)/g, '');
   if (parseFloat(val)) { return val; }
   return null;
}

function formatNumber(value: number): string {
   console.warn('formatNumber is deprecated. Please use Alkami.CurrencyHelper.formatCurrency instead.');
   // if it is null, return 0
   if (value == null) {
      return '0.00';
   }
   if (typeof value === 'string') {
      value = unformatMoney(value);
   }
   if (isNaN(value)) {
      return '0.00';
   }
   return value.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
}

function formatMoney(value: number): string {
   console.warn('formatMoney is deprecated. Please use Alkami.CurrencyHelper.formatCurrency instead.');
   const formattedAmount: string = formatNumber(value);
   if (value < 0) {
      return '($' + formattedAmount.replace('-', '') + ')';
   }
   return '$' + formattedAmount;
}

function unformatMoney(value: any): number {
   console.warn('unformatMoney has been deprecated. Please use Alkami.Utils.CurrencyHelper.parseCurrency instead.')
   if (value === undefined || value === null) {
      return value;
   }
   let money: number = parseFloat(value.replace(/[^0-9.]/g, ''));
   if (value.startsWith('(')) {
      money = -money;
   }
   return money;
}

/********************************
  UI Helpers
********************************/
function bindDecimalFields() {
   const decimalFieldElements = Array.from(document.querySelectorAll('input[type=text].decimal'));
   decimalFieldElements.forEach(function(decimalFieldElement) {
      decimalFieldElement.addEventListener('keypress', function(e) {
         function stopEvent() {
            e.stopPropagation();
            e.preventDefault();
         }
         const noNegative: boolean = this.classList.contains('no-negative');

         // TODO (e as KeyboardEvent).which is deprecated, should use e.key or e.code
         if ((e as KeyboardEvent).which === 0 || (e as KeyboardEvent).which === Alkami.Helpers.KEY_CODES.DELETE || (e as KeyboardEvent).which === Alkami.Helpers.KEY_CODES.BACKSPACE || (e as KeyboardEvent).which === Alkami.Helpers.KEY_CODES.PERIOD || (e as KeyboardEvent).keyCode === Alkami.Helpers.KEY_CODES.TAB || (e as KeyboardEvent).which === Alkami.Helpers.KEY_CODES.COMMA) { return true; }
         if (((e as KeyboardEvent).which === Alkami.Helpers.KEY_CODES.NUMPAD_SUBTRACT || (e as KeyboardEvent).which === 45) && noNegative) { stopEvent(); return false; }
         if (((e as KeyboardEvent).which === Alkami.Helpers.KEY_CODES.NUMPAD_SUBTRACT || (e as KeyboardEvent).which === 45) && /\-/.test(this.value)) { stopEvent(); return false; } // minus key and already has minus
         if ((e as KeyboardEvent).which >= 44 && (e as KeyboardEvent).which <= 57) { return true; }

         stopEvent();
         return false;
      });
      decimalFieldElement.addEventListener('blur', function() {
         this.value = unlocalizeAmount(this.value);
      });

      const p = (decimalFieldElement as HTMLInputElement).value.match(/(\d)(?=(\d{3})+(?!\d))/);
      if (!p) {
         return true; // continue
      }
      // format number with commas.
      (decimalFieldElement as HTMLInputElement).value = (decimalFieldElement as HTMLInputElement).value.replace(/(\d)(?=(\d{3})+(?!\d))/, '$1,');
      return true;
   });
}

function bindRequiredFields(): void {
   Array.from(document.querySelectorAll('[required]')).forEach(function(n: Element): void {
      const labelElement = document.querySelector(`label[for=${n.id}]`);
      if (labelElement) {
         labelElement.classList.add('required');
      }
   });
}

async function instructify(element: HTMLElement): Promise<void> {
   element.addEventListener('click', async (event) => {
       event.preventDefault();
     const url = new URL(element.getAttribute('href'), document.baseURI);

     if (new URL(document.baseURI).origin !== url.origin) {
        throw new Error('not a local url. may be unsafe.')
     }

     const contentElement: HTMLElement = Alkami.Dom.parseHTML('<div><div class="loading-indicator">Loading...</div></div>');
     const promptComponent = Alkami.Helpers.createCloseDialog(contentElement, { title: element.innerText || 'Help', cls: 'instructions', destroyOnClose: true });
     promptComponent.open = true;

     const response = await fetch(url.href);
     const data = await response.text();

     contentElement.innerHTML = data;

     const sideNavElement = contentElement.querySelector('#modal_sidenav');
     if (!sideNavElement) {
        return;
     }
     
     const parent = contentElement.querySelector('.tab-panel-container');
     parent.querySelectorAll('.panel').forEach((panelElement) => Alkami.Dom.hideElement(panelElement));

     const listItemElements = sideNavElement.querySelectorAll('.list-item');
     listItemElements.forEach((listItemElement) => {
        listItemElement.addEventListener('click', (listItemEvent) => {
           listItemEvent.preventDefault();
           listItemEvent.stopPropagation();
           
           if (listItemElement.classList.contains('current')) {
              return;
           }

           const currentActivePanelItem = parent.querySelector('.panel.active');
           currentActivePanelItem.classList.remove('active');
           Alkami.Dom.hideElement(currentActivePanelItem);

           const newlyActivePanelItem = contentElement.querySelector(`.panel${listItemElement.getAttribute('href')}`);
           newlyActivePanelItem.classList.add('active');
           Alkami.Dom.showElement(newlyActivePanelItem);
        });
     });
     
     const firstListItemElement = sideNavElement.querySelector('ul li:first-child a');
     firstListItemElement.classList.add('current');
     const firstPanel = contentElement.querySelector(`.panel${firstListItemElement.getAttribute('href')}`);
     firstPanel.classList.add('active');
     Alkami.Dom.showElement(firstPanel);
   });
}